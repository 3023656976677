/* Custom SCSS by Impulse Digital Pty Ltd: http://impulsedigital.com.au */

/* color variables */

/*$darkgrey: #1a1d17;
$white: #ffffff;
$green: #80c22b;
$lightgrey: #eff1ed;
$midgrey:#959595;
$stroke:#e5e7e2;*/

/* global */

body {
	font-family: $bodyfont;
	background:$white;
}

body p {
	font-size:16px;
	font-family: $bodyfont;
}

strong {
	font-family: 'maison_neuebold';
}

h1 {
	font-size:70px;
	font-family: $headingfont;
	line-height:1.2em;
}

h2 {
	font-size:36px;
	font-family: $headingfont;
	text-transform: uppercase;
	line-height:1.2em;
}

h3 {
	font-family:$headingfontsemi;
	font-size:24px;
	line-height:1.2em;
	margin-top:0px;
}

h4 {
	font-size:18px;
	font-family: $headingfont;
	text-transform: uppercase;
	margin-top:0px;
	line-height:1.2em;
}

h5 {
	font-size:24px;
	font-family:$bodyfont;
	line-height:1.2em;
	margin-top:0px;
}

hr {
	border-top:1px solid $stroke;
	margin: 50px 0px;
}

::selection {
  background: $green; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $green; /* Gecko Browsers */
}

.button {
	background: $green;
	font-size:18px;
	font-family: $headingfont;
	color:$darkgrey;
	line-height: 50px;
	border-radius:50px;
	padding:0 30px;
	display:block;
	float:left;
	transition: all 0.2s ease-in-out;
	&:hover {
		background:$darkgrey;
		color:$white;
	}
}

.button-alt {
	box-shadow:inset 0px 0px 0px 2px $white;
	background:transparent;
	color:$white;
	&:hover {
		background:$white;
		color:$darkgrey;
	}
}

.main-container {
	padding-top:50px !important;
}

/* tabs */

/* Style the list */
ul.tab {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 0px;
    background-color: transparent;
    margin:0 auto;
    float:none;
    margin-bottom:20px;
    display:flex;
    align-items:center;
    justify-content:center;
}

/* Float the list items side by side */
ul.tab li {float: left; list-style:none;}

/* Style the links inside the list items */
ul.tab li a {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    transition: 0.3s;
    font-size: 18px;
    font-family:$headingfont;
    text-transform: uppercase;
    color:$midgrey !important;
    transition:none;
    border-bottom:3px solid $white;
}

/* Change background color of links on hover */
ul.tab li a:hover {background-color: transparent; color:$darkgrey !important; border-bottom:3px solid $white !important;}

/* Create an active/current tablink class */
ul.tab li a.active {background-color: transparent; border-bottom: 3px solid $green; color:$darkgrey !important;}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 0;
    border: 0px solid #ccc;
    border-top: none;
}

.tabcontent {
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s; /* Fading effect takes 1 second */
}

@-webkit-keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}

/* content slider */

.unslider{overflow:auto;margin:0;padding:0}.unslider-wrap{position:relative}.unslider-wrap.unslider-carousel>li{float:left}.unslider-vertical>ul{height:100%}.unslider-vertical li{float:none;width:100%}.unslider-fade{position:relative}.unslider-fade .unslider-wrap li{position:absolute;left:0;top:0;right:0;z-index:8}.unslider-fade .unslider-wrap li.unslider-active{z-index:10}.unslider li,.unslider ol,.unslider ul{list-style:none;margin:0;padding:0;border:none}.unslider-arrow{position:absolute;left:20px;z-index:2;cursor:pointer}.unslider-arrow.next{left:auto;right:20px}

.unslider-nav ol {
  list-style: none;
  text-align: center;
}
.unslider-nav ol li {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 4px;
  background: transparent;
  border-radius: 5px;
  overflow: hidden;
  text-indent: -999em;
  border: 2px solid #fff;
  cursor: pointer;
}
.unslider-nav ol li.unslider-active {
  background: #fff;
  cursor: default;
}

/* header */

.top-line {
	background:$darkgrey;
	color:$white;
	border-bottom:0px;
	.content-width {
		width:100%;
		max-width:100%;
		padding: 10px 30px 8px 30px;
		float:left;
		width:100%;
		clear:both;
		font-size:14px;
		p {
			margin-bottom:0px;
			font-size:14px;
		}
		.top-links-left {
			a {
				color:$white;
				border-bottom:1px solid $white;
				&:hover {
					border-bottom:1px solid $green;
				}
			}
		}
		.top-links-right {
			a {
				font-size:14px;
				color:$white;
				font-family: $bodyfontbold;
			}
		}
	}
}

#header {
	height:100px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	.header-content {
		.logo {
			margin:20px;
		}
		.logo-large {
			width:182px;
			height:auto;
		}
		.logo-small {
			width:182px;
			height:auto;
		}
		.content-width {
			width:100%;
			max-width:100%;
			float:left;
			width:100%;
			clear:both;
			font-size:14px;
		}
		.main-menu-right {
			position:absolute;
			right:30px;
			font-size:14px;
			font-family:$bodyfontbold;
			text-transform: capitalize;
			width:270px;
			ul.account-menu {
				float:left;
				line-height:97px;
				li {
					width:170px;
					ul {
						float:left;
						padding:10px !important;
						&.level0{
							background: $lightgrey !important;
						}
					}
					a {
						font-size:14px !important;
						font-family:$bodyfontbold !important;
						color:$darkgrey !important;
						&:hover{
							color:$green !important;
						}
					}
				}
			}
			.fa {
				margin:0 5px;
			}
			.fa-shopping-cart {
				margin-right:0px;
			}
			.fa-search {
				padding-right:42px;
			}
		}
		.header-nav {
			background:$white;
			width:75%;
			float:right;
			ul.nav-primary {
				line-height:97px;
				font-family: $headingfont;
				font-size:18px;
				li.active {
					background:transparent;
					border-bottom:3px solid $green;
				}
				li.level0{
					&:hover {
						border-bottom:3px solid $green;
						background:$lightgrey;
						> a {
							background:$lightgrey;
						}
					}
					> a{
						color:$darkgrey;
						padding:0 15px;
						font-size:18px;
					}
				}
				ul.level0:not(.account-menu){
					position:absolute;
					top:100%;
					left:0;
					z-index:3000;
					background:$darkblack;
					-moz-column-count: 4;
					-webkit-column-count: 4;
					column-count: 4;
					a{
						color:$white;
						text-transform: capitalize;
					}
					a.level1{
						color:$green;
					}
					li.level1{
						&:hover,&.menu-active{
							background:none;
							a{
								color: $white;
								&.level1{
									color:$green;
								}
							}
						}
					}
					ul.level1{
						display:block !important;
						position:static;
						background:none;
						padding:0;
					}
				}
				li {
					transition:none;
					ul {
						position:static;
						padding:0;
						background:transparent;
						border:0;
						li {
							padding: 5px 0;
							float:left;
							width:100%;
							font-family: $headingfontlight;
							&:hover{
								background: transparent;
							}
							a {
								transition: all 0.2s ease-in-out;
							}
							a.has-children::before {
								display:none;
							}
							i {
								display:none;
							}
							ul {
								top:0;
							}
						}
						li.active {
							border-bottom:1px solid $white;
							a {
								color:$green;
							}
						}
					}
				}
				ul.account-menu {
					ul.level0{
						-moz-column-count: 1;
						-webkit-column-count: 1;
						column-count: 1;
						li.level1{
							a.level1{
								color:$darkgrey;
								&:hover{
									color:$green;
								}
							}
						}
					}
					li.level0{
						position:relative;
					}
					li{
						&:hover{
							background:$lightgrey;
							border-color:$lightgrey;
							a{
								background:$lightgrey;
							}
						}
					}
					ul {
						position:absolute;
						top:100px;
						padding:10px;
						background:$lightgrey;
						border:0;
						width:100%;
						li {
							border-bottom:0;
							padding:5px 10px;
							float:left;
							width:100%;
							font-family: $headingfontlight;
							a {
								padding:0;
								font-size:16px;
								transition: all 0.2s ease-in-out;
								color:$darkgrey;
							}
							&:hover {
								color: $green;
								background: transparent;
								a {
									color:$green;
									background: transparent;
								}
							}
						}
					}
				}
				a.icon {
					color:$darkgrey;
					&:hover {
						color:$green;
					}
				}
			}
		}
	}
}

/* header search */

.search-link.active-search i {
	color:$green;
}

#search-dropdown {
	background:$lightgrey;
	padding:50px 20px;
	display:none;
	.header-search {
		max-width:650px;
		margin:0 auto;
		float:none;
		position:relative;
		#search {
			color:$darkgrey;
			font-family: $headingfontsemi;
			font-size:18px;
		}
		label {
			color:$midgrey;
			font-family: $headingfontsemi;
			font-size:18px;
			line-height:60px;
			padding-left:30px;
		}
		.is-focused {
			label {
				border-bottom:0px;
				top:-30px;
			}
			label:after {
				display:none;
			}
		}
		input {
			/*border:2px solid $midgrey;
			height:75px;
			padding:0 30px;
			border-radius:40px;
			width:90%;*/
		}
		.search-button {
			border:0 solid $green;
			background:transparent;
			position:absolute;
			right:20px;
			top:27px;
		}
	}
}

/* home page */

#home-hero {
	position:relative;
	img {
		width:100%;
		height:auto;
		min-width:1500px;
	}
	.hero-content {
		position:absolute;
		z-index:10;
		top:30%;
		width:100%;
		padding:0 15px;
		h3 {
			font-size:30px;
			font-family:$headingfontlight;
		}
		.hero-content-inner {
			position:relative;
			max-width:1170px;
			margin:0 auto;
			display:block;
			float:none;
			text-align:left;
			color:$white;
			h1 {
				line-height:1.1em;
			}
		}
		.button-container {
			float:none;
			display:block;
			margin:0 auto;
			width:100%;
			margin-top:20px;
			.button {
				margin: 10px;
			}
		}
	}
	.unslider-nav ol li {
		width:15px;
		height:15px;
		border-radius:15px;
		display:none;
	}
	.unslider-arrow {
		display:none;
	}
}

#home-selling-points {
	padding:50px 0px;
	background: $lightgrey;
	.mdl-grid {
		max-width:1170px;
		margin:0 auto;
		.fa {
			float:left;
			margin-bottom:60px;
			margin-right:30px;
		}
	}
}

.home-callout {
	padding:50px 0px;
	background:$green;
	float:left;
	width:100%;
	clear:both;
	margin-top:0px;
	text-align:center;
	h2 {
		color:$white;
		margin:0px !important;
	}
	a {
		color:$white;
		text-decoration: underline;
	}
}

#home-video.mdl-grid {
	max-width:1170px;
	margin:0 auto;
	margin-bottom:50px;
	iframe {
		margin:0 auto;
		display:block;
		float:none;
		width:100% !important;
		max-width:650px;
	}
	.column-left {
		padding-right:16px;
		border-right:1px solid $lightgrey;
	}
}

/* home tabs */

body.cms-home {
	.footer-container {
		margin-top:0px;
	}
	h2 {
		text-align:center;
		margin-bottom:40px;
	}
	.tabcontent {
		.products-grid {
			margin-bottom:20px;
		}
		.item {
			margin:2%;
			width:21%;
			padding:0;
			border:1px solid $stroke;
			.product-image {
				img {
					max-width:1000%;
					width:100%;
				}
			}
		}
	}
	.col-main {
		a {
			color:$darkgrey;
			transition: none;
			&:hover {
				color:$darkgrey;
				border-bottom:2px solid $green;
			}
		}
	}
	.suppliers {
		margin-bottom:20px;
		img {
			opacity:1;
			transition: all 0.2s ease-in-out;
		}
		img:hover {
			opacity:0.5;
		}
		a {
			text-decoration: none;
			border-bottom:0px !important;
		}
	}
}

/* home testimonials */

#home-testimonials {
	margin-top:50px;
	padding:50px;
	background:$lightgrey;
	.testimonial-content {
		max-width:1170px;
		margin:0 auto;
		float:none;
		display:block;
		background:$white;
		padding:80px;
		text-align:center;
		position:relative;
		h3 {
			color:$midgrey;
			margin-bottom:40px;
		}
		.unslider-arrow.next {
			color:$darkgrey;
			top:47%;
			transition:all 0.2s ease-in-out;
			&:hover {
				color:$green;
			}
		}
		.unslider-arrow.prev {
			top:47%;
			color:$darkgrey;
			transition: all 0.2s ease-in-out;
			&:hover {
				color:$green;
			}
		}
	}
}

/* footer */

.footer-container {
	background:$darkgrey;
	h4 {
		color:$midgrey;
	}
	h5 {
		a {
			border-bottom:2px solid $white;
			transition: all 0.2s ease-in-out;
			&:hover {
				border-bottom:2px solid $green;
				color:$green;
			}
		}
	}
	a:hover {
		color:$white;
		border-bottom:2px solid $green;
	}
	.newsletter {
		background:transparent;
		padding:0px;
		#newsletter {
			color:$white;
			font-family: $headingfontsemi;
			font-size:16px;
		}
		label {
			color:$white;
			font-family: $headingfontsemi;
			font-size:16px;
			line-height:39px;
			padding-left:30px;
		}
		.is-focused {
			label {
				border-bottom:0px;
				top:-10px;
			}
			label:after {
				display:none;
			}
		}
		input {
			border:2px solid $midgrey;
			height:50px;
			padding:0 30px;
			border-radius:25px;
		}
		.button {
			border-radius:25px;
			padding:0 30px;
			height:50px;
			border:2px solid $green;
			&:hover {
				background:$darkgrey;
				border:2px solid $midgrey;
				color:$midgrey;
			}
		}
	}
	.copyright {
		background:$darkgrey;
		border-top:1px solid $midgrey;
		padding:20px 0px;
		font-size:14px;
	}
}

body.cms-home {
	.footer-container {
		margin-top:-10%;
		padding-top:10%;
	}
}

.footer-container {
	padding-top:30px;
}

/* inner pages */

#home-hero.inner {
	p {
		margin-bottom:0px;
	}
	.hero-content {
		top:25%;
		img {
			min-width:960px;
		}
	}
}

body.cms-page-view, body.catalog-category-view, body.catalog-product-view {
	.main-container {
		padding-top:0px !important;
		width:100%;
		max-width:100%;
		padding:0px;
		.main {
			padding:0px;
			.category-head {
				display:none;
			}
			.breadcrumbs {
				padding:10px 15px;
				background:$darkgrey;
				color:$white;
				text-transform: capitalize;
				ul {
					overflow:hidden;
				}
				a {
					padding:0 15px;
					color:$white;
					text-transform: capitalize;
					background:$darkgrey;
				}
				li {
					color:$white;
					padding:0 8px;
					margin-bottom:0px;
					strong {
						color:$white;
						text-transform: capitalize;
						background:$darkgrey;
					}
				}
			}
		}
	}
	.col-wrapper.mdl-grid--stretch, .col-wrapper.mdl-grid {
		max-width:1170px;
		float:none;
		margin:0 auto;
		width:100%;
		padding:50px 0;
		.page-title {
			display:none;
		}
		.mdl-cell {
			padding:10px;
		}
		.mdl-grid {
			padding:10px;
		}
		.scaffold-form {
			.legend {
				display:none;
			}
			input[type="text"], input[type="tel"], input[type="email"], textarea {
				width:100%;
				max-width:100%;
				border:1px solid $darkgrey;
			}
			li {
				list-style:none;
			}
			label {
				em {
					margin-right:10px;
				}
			}
		}
		.narrow-content {
			max-width:750px;
			margin:0 auto;
			float:none;
		}
	}
}

body.catalog-product-view {
	.col-wrapper.mdl-grid--stretch {
		.mdl-grid {
			padding:0px;
		}
		.mdl-cell {
			padding:2.5px;
		}
	}
}

/* product catalog */

#narrow-by-list {
	dt {
		font-size:18px !important;
		font-family: $headingfont;
		text-transform: uppercase;
		margin-top:0px;
		line-height:1.2em;
		color: $darkgrey;
	}
	.price-range {
		input[type="text"] {
			border:1px solid $darkgrey;
		}
	}
}

 #polarcore .block-layered-nav .block-subtitle {
 	display:block;
 	font-size:18px !important;
	font-family: $headingfont;
	text-transform: uppercase;
	margin-top:0px;
	line-height:1.2em;
	color: $darkgrey;
 }

 #polarcore .block-layered-nav .block-title {
 	display:block;
 	font-size:24px !important;
	font-family: $headingfont;
	text-transform: uppercase;
	margin-top:0px;
	line-height:1.2em;
	color: $darkgrey;
	padding:15px;
	background:$lightgrey;
	margin-bottom:20px;
 }

 .col-wrapper aside.sidebar .block-subtitle--filter:before {
 	border:0px;
 }

 #polarcore .block-layered-nav .currently {
 	margin-bottom:20px;
 }

 .category-products {
 	.toolbar {
 		margin:0 0 10px 0;
 	}
 	.products-grid {
 		li {
 			border:1px solid $stroke;
 			margin:2%;
 			width:29%;
 			padding:0;
 			img {
 				max-width:1000%;
 				width:100%;
 			}
 		}
 	}
 }

 .nuva-dropdown-wrapper a {
 	border:1px solid $darkgrey;
 	color:$darkgrey;
 }

/* product detail */

.product-view .product-shop .inner {
	float:left;
	width:100%;
}

.product-view {
	.product-collateral {
		.mdl-tabs {
			img {
				width:auto !important;
				max-width:100%;
			}
		}
	}
}

.product-view .add-to-cart button.btn-cart {
	background:$green;
	&:hover {
		background:$darkgrey;
	}
}

.validation-advice {
	position:relative;
}

.product-view .configurable-swatch-list {
	display:block !important;
}

.product-view .configurable-swatch-list li {
	width:40px;
	height:40px;
	float:left;
	display:block;
	clear:none;
}

.product-view .short-description, .product-view #tab-description, .product-view #tab-custom-tab {
	font-size:16px;
	line-height:1.4em;
}

.mdl-tabs__tab-bar {
	margin:40px 0;
	a {
		font-family:$headingfont;
		color:$midgrey;
		font-size:18px;
		}
}

.product-view .add-to-cart .qty, .product-view .add-to-cart label[for=qty] {
	display:block;
}

.product-view .add-to-cart-wrapper input[type="text"] {
	width:100%;
	border:1px solid $darkgrey;
}

.product-view, .checkout-cart-index {
	.product-collateral {
		margin-bottom:50px;
	}
	.box-up-sell {
		padding-bottom:50px;
		margin-bottom:50px;
		border-bottom:1px solid $stroke;
		h2 {
			margin-bottom:20px;
		}
		&:before {
			display:none;
		}
		.slick-slider {
			margin-bottom:0px;
		}
		.item {
			padding:0 0;
			margin: 10px;
			border:1px solid $stroke;
			min-width: 246px;
			img {
				width:100%;
				max-width:1000%;
			}
			&:hover {
				box-shadow: 0 0 0 3px #f0f0f0;
			}
		}
	}
	.box-up-sell-2 {
		border-bottom:0px;
		margin-bottom:0px;
		padding-bottom:0px;
	}
}

/* cart */

.checkout-cart-index {
	.main {
		#co-shipping-method-form {
			float:left;
			width:100%;
		}
		#discount-coupon-form {
			.mdl-textfield__input {
				border-bottom:1px solid $darkgrey;
			}
		}
		#shopping-cart-table {
			li {
				padding:20px;
				background:$lightgrey;
				.btn-remove {
					background:$lightgrey;
				}
				input {
					border:1px solid $darkgrey;
				}
			}
			li.cart-footer {
				background:transparent;
				border-top:1px solid $stroke;
				float:left;
				width:100%;
				.button {
					font-size:14px;
					line-height:40px;
					padding:0 20px;
					background:$darkgrey;
					color:$white;
					margin-bottom:10px;
					&:hover {
						background:$lightgrey;
						color:$darkgrey;
					}
				} 
			}
		}
		.cart-total-price-wrapper {
			text-align:left;
			.cart-totals {
				text-transform: none;
				.mdl-grid {
					margin:0px;
				}
				.shipping-form {
					select {
						width:95%;
						margin-bottom:20px;
						font-size:16px;
						padding:20px;
					}
					input[type="text"] {
						border:1px solid $darkgrey;
						width:95%;
						margin-bottom:20px;
						font-size:16px;
					}
					.button {
						font-size:14px;
						line-height:40px;
						padding:0 20px;
						background:$darkgrey;
						color:$white;
						&:hover {
							background:$lightgrey;
							color:$darkgrey;
						}
					}
				}
				#discount-coupon-form {
					display:block;
					float:none;
					width:100%;
					.discount-form {
						margin:0px;
					}
					.discount {
						padding:0px;
					}
					.button {
						font-size:14px;
						line-height:40px;
						padding:0 20px;
						background:$darkgrey;
						color:$white;
						margin-bottom:10px;
						&:hover {
							background:$lightgrey;
							color:$darkgrey;
						}
					}
				}
				.btn-save {
					font-family:$headingfont;
					background:$midgrey;
					color:$white;
					text-transform: uppercase;
					font-size:16px;
					text-decoration: none;
					margin-left:0px;
					margin-right:0px;
					margin-top:10px;
					&:hover {
						color:$darkgrey;
						background:$lightgrey;
					}
				}
			}
		}
		.cart {
			.btn-checkout {
				background:$green;
				padding:0 20px;
				transition: all 0.2s ease-in-out;
				margin-bottom:10px;
				span {
					&:after {
						vertical-align: middle;
						margin-top:4px;
					}
				}
				&:hover {
					background:$darkgrey;
				}
			}
			.checkout-types.bottom {
				a {
					color:$darkgrey;
				}
			}
		}
		.products-grid {
			button.btn-cart {
				background:$green;
				font-size:14px;
				line-height:40px;
				&:before {
					vertical-align: middle;
				}
				&:hover {
					background:$darkgrey;
				}
			}
			.product-name {
				min-height:0px;
			}
		}
		.crosssell {
			border-bottom:0px;
			margin:0px;
			padding:0px;
		}
	}
} 

/* mini cart sidebar */

#header-cart {
	position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background: $lightgrey;
    z-index: 9999;
    width:0px;
    overflow:hidden;
    transition:all 0.1s ease-in-out;
    font-size:14px;
    overflow-y:scroll;
}

#header-cart.active {
	width:300px;
	padding:40px 30px;
}

#header-cart p {
	font-size:14px;
}


#polarcore {
	transition: all 0.1s ease-in-out;
}
.slideleft {
	width: calc(100% - 300px);
}

.header-minicart a.skip-cart {
	position: absolute;
    top: 87px;
    z-index: 999;
    right: 40px;
    color:$darkgrey;
}

.header-minicart a.skip-cart.large-screens {
	display:block;
}

.header-minicart a.skip-cart.small-screens {
	display:none;
}

#header-cart a.close {
	top:20px;
	right:20px;
	font-size:14px;
	font-family: $bodyfontbold;
	position:absolute;
	color:$darkgrey;
	display:block;
	text-decoration: underline;
}
.header-minicart i.active {
	color:$green;
}

#header-cart {
	li.item {
		position:relative;
		float:left;
		width:100%;
		margin-bottom:15px;
		padding-bottom:15px;
		border-bottom:1px solid $white;
		img {
			float:left;
			margin-right:10px;
		}
		.edit-remove {
			position:absolute;
			top:0;
			right:0;
			a {
				color:$darkgrey;
			}
		}
	}
	.product-name {
		font-family: $bodyfontbold;
		color:$darkgrey;
		margin-bottom:0px;
		width:80%;
		a {
			color:$darkgrey;
		}
	}
	.info-wrapper {
		float:right;
		width:180px;
		display:block;
	}
	.qty-wrapper {
		input {
			border:10px;
			border:1px solid $darkgrey;
			float:left;
			width:50%;
		}
		.button {
			font-size: 14px;
			float:left;
			padding:5px 10px;
			line-height:auto;
			height:auto;
			line-height:25px;
			margin-left:10px;
		}
	}
	.truncated {
		float:right;
		clear:both;
		width:180px;
		dt {
			font-family: $bodyfontbold;
		}
		dt,dt {
			float:left;
			width:50%;
		}
	}
	.subtotal {
		.label {
			font-family: $bodyfontbold;
		}
	}
	.button {
		font-size:14px;
		line-height:40px;
		height:40px;
		padding:0 15px;
	}
	.button-alt {
		margin-left:10px;
		color: $darkgrey;
		box-shadow: inset 0px 0px 0px 2px $darkgrey;
		&:hover {
			background:$darkgrey;
			color:$white;
		}
	}
}

#minicart-success-message {
	color:$green;
	font-family: $bodyfontbold;
}

/* checkout */

.calendar .button {
	text-align: center;
    padding: 1px;
    border-top: 1px solid #fff;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    border-left: 1px solid #fff;
    float: none;
    padding: 0;
    border-radius: 0px;
    line-height: auto;
    line-height: 1em;
    /* display: inline-block; */
    /* float: left; */
    transition: none;
    font-size: 13px;
    font-weight: normal;
    background: transparent;
    display: table-cell;
}

.checkout-onepage-index {
	h1 {
		text-transform: none;
	}
	.section.allow {
		.step-title {
			color:$midgrey;
			.number {
				background:$midgrey;
			}
		}
	}
	.section.active {
		.step-title {
			color:$green;
			.number {
				background:$green;
			}
		}
	}
	.section {
		float:left;
		width:100%;
		clear:both;
		margin-bottom:20px;
		padding-bottom:20px;
		border-bottom:1px solid $stroke;
		.step-title {
			margin-bottom:0px;
			.number {
				font-family: $headingfont;
				width:40px;
				height:40px;
				background:$darkgrey;
				color:$white;
				border-radius: 20px;
				padding:7px 15px;
				margin-right:10px;
			}
			h3 {
				line-height:40px;
			}
		}
		&.active {
			border:0px;
			padding:0px;
			.step-title {
				margin-bottom:20px;
			}
		}
	}
	#checkout-progress-wrapper {
		dt {
			text-transform: none;
			font-family: $bodyfontbold;
			padding-bottom:10px;
			margin-bottom:10px;
			border-bottom:1px solid $stroke;
		}
		.block-title {
			padding:15px;
			background:$lightgrey;
			h4 {
				margin-bottom:0px;
			}
		}
		.complete {
			text-transform: none;
			font-size:16px;
			address {
				font-size:16px;
			}
		}
	}
	#checkoutSteps {
		.button {
			margin-top:10px;
		}
		.mdl-textfield__input {
			border-bottom:1px solid $darkgrey;
		}
		.input-box {
			padding-bottom:20px;
		}
		input[type="text"], input[type="tel"], input[type="number"], textarea {
			width:100%;
			border:1px solid $darkgrey;
			padding:10px;
			font-size:16px;
		}
		select {
			width:100%;
			font-size:16px;
			padding:10px;
		}
		.mdl-textfield__label {
			top:30px;
			left:10px;
			color:$midgrey;
			overflow:hidden;
			font-family:$bodyfont;
		}
		label {
			color:$darkgrey;
			font-size:16px;
			font-family: $bodyfontbold;
		}
		.mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .mdl-textfield--floating-label.is-focused .mdl-textfield__label {
			top:0px;
			left:0px;
			color:$darkgrey;
			font-size:16px;
			font-family: $bodyfontbold;
		}
		.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
			color:$green;
		}
	}
	#checkout-review-table {
		text-transform: none;
		thead {
			background:$lightgrey;
			font-family: $bodyfontbold;
		}
	}
}

.customer-account {
	.page-title {
		float:left;
		width:100%;
		clear:both;
		h1 {
			font-size:36px;
			text-transform: uppercase;
			margin-bottom:20px;
		}
	}
	.title-buttons {
		margin-bottom:20px;
	}
	.data-table {
		thead {
			background:$lightgrey;
			font-family: $bodyfontbold;
			text-transform: none;
		}
	}
	.block-account {
		.block-title {
			font-size:24px;
			font-family: $headingfont;
			background:$lightgrey;
			padding:15px;
			text-transform: uppercase;
			margin-bottom:20px;
		}
	}
	.col-left {
		margin-top:30px;
	}
	.block-content {
		a {
			font-family: $headingfontlight;
			font-size:16px;
			line-height:2em;
			color:$darkgrey;
			padding-left:20px;
			border-left:3px solid $lightgrey;
			float:left;
			width:100%;
		}
		.current a {
			font-family: $headingfont;
			border-left:3px solid $green;
		}
	}
}

address {
	font-size:16px;
	line-height:1.2em;
}

/*generic inner */

.col1-layout {
	.main {
		font-size:16px;
		.col-wrapper {
			img {
				width:100%;
				margin-bottom:20px;
			}
			p {
				font-size:16px;
				margin-bottom:20px;
			}
			h1, h2, h3, h4, h5, h6 {
				margin-bottom:20px;
			}
			ul, ol {
				font-size:16px;
				margin-bottom:20px;
			}
		}
	}
}

#messages_product_view {
	ul {
		margin-bottom:0px;
	}
	a {
		color:$white;
		text-decoration:underline;
	}
}

.messages {
	ul {
		margin-bottom:0px !important;
		margin-top:0px !important;
	}
}

/* cool shit pdf download */

#home-selling-points.home-callout.home-pdf {
	margin-bottom:0px;
	margin-top:50px;
	background:$darkgrey;
	h2 {
		margin-bottom:20px !important;
	}
	p {
		color:$white;
		margin-bottom:20px;
	}
	.mdl-grid {
		max-width:800px;
		.newsletter {
			.mdl-textfield {
				float:left;
				display:block;
				width:calc(100% - 170px);
			}
			.clear {
				float:right;
				width:160px;
				padding:20px 0px;
			}
		}
	}
}

#home-testimonials {
	margin-top:0px;
}

/* campaign monitor integration */

.subscribe-button-inner {
	float:right;
}

.cms-blog {
	p.title {
		font-weight:bold;
		font-size:26px !important;
		padding-top:20px;
		margin-top:20px;
		border-top:1px solid #959595;
	}
	.summary {
		img {
			max-width:300px !important;
		}
	}
}

@media (max-width: 1024px) {
	.subscribe-button-inner {
		float:left;
	}
}

/* responsive */

@media (max-width:1360px) {
	#header-cart.active {
		display:none;
	}
	.slideleft {
		width:100%;
	}
	.header-minicart a.skip-cart.large-screens {
		display:none;
	}
	.header-minicart a.skip-cart.small-screens {
		display:block;
	}
	#home-hero {
		.hero-content {
			top:80px;
		}
	}
}

@media (max-width: 1250px) {

	/* menu */
	#header {
		.header-content {
			.logo {
				width:150px;
			}
			.header-nav {
				ul.nav-primary {
					li {
						a {
							padding:0 10px;
						}
					}
				}
			}
		}
	}
	/* inner header */
	#home-hero.inner {
		.hero-content {
			top:15%;
		}
	}
}

@media (max-width: 1024px) {
	/* mobile menu */
	.mdl-layout__drawer-button, .mdl-layout__drawer-button .menu-label {
		display:block;
	}
	#home-hero {
		margin-top:66.14px;
		.hero-content {
			.hero-content-inner {
				text-align:center;
			}
			.button-container {
				width:380px;
			}
		}
	}
	#header {
		height:66.14px;
		.header-content {
			.logo {
				margin:10px;
				float:right;
				width:auto;
				width:140px;
			}
			.header-nav {
				display:none;
			}
		}
	}
	.top-line {
		display:none;
	}
	#nav-mobile {
		.fa-chevron-down {
			display:none;
		}
	}
	.mdl-layout__drawer {
		background:$darkgrey;
	}
	ul.top-links__mobile {
		background:$darkgrey;
	}
	.mobile-menu-top {
		color:$white;
		#search_mini_form {
			padding:15px;
			.mdl-textfield {
				padding:0px;
			}
			input {
				background:$white;
				color:$darkgrey;
				padding:10px;
				border-radius: 30px;
			}
		}
		.button {
			margin:10px 15px;
			margin-bottom:0px;
		}
	}
	.mobile-sub-menu {
		background:$darkgrey;
		padding:10px 15px;
		a {
			color:$white;
			line-height:30px;
		}
		.main-menu-right {
			padding-bottom:10px;
			margin-bottom:10px;
			border-bottom:1px solid $midgrey;
		}
	}
	.top-notice-mobile {
		background:$darkgrey;
		color:$white;
		padding:10px 15px;
		border-bottom: 1px solid $midgrey;
		p {
			margin-bottom:0px;
		}
	}
	/*home header*/
	#home-hero {
		.hero-content {
			top:40px;
			.button-container {
				margin-top:20px;
			}
		}
	}
	h1 {
		font-size:50px;
		font-family: $headingfont;
	}
	h2 {
		font-size:28px;
		font-family: $headingfont;
		text-transform: uppercase;
	}

	h3 {
		font-family:$headingfontsemi;
		font-size:20px;
	}

	h4 {
		font-size:18px;
		font-family: $headingfont;
		text-transform: uppercase;
		margin-top:0px;
	}
	h5 {
		font-size:20px;
		font-family:$bodyfont;
		line-height:1.2em;
	}
	.header-minicart a.skip-cart.small-screens {
		display:none;
	}
}

@media (max-width: 767px) {
	#home-hero {
		.hero-content {
			top:50px;
			h3 {
				font-size:20px;
			}
			.button-container {
				margin-top:20px;
				.button {
					margin:5px;
				}
			}
		}
	}

	#home-video.mdl-grid {
		.column-left {
			padding-right:0px;
			border-right:0px;
			padding-bottom:16px;
			border-bottom:1px solid $lightgrey;
		}
	}
	h1 {
		font-size:36px;
		font-family: $headingfont;
	}
	h2 {
		font-size:24px;
		font-family: $headingfont;
		text-transform: uppercase;
	}

	h3 {
		font-family:$headingfontsemi;
		font-size:20px;
	}

	h4 {
		font-size:18px;
		font-family: $headingfont;
		text-transform: uppercase;
		margin-top:0px;
	}
	h5 {
		font-size:20px;
		font-family:$bodyfont;
		line-height:1.2em;
	}
	/* home tabs */
	body.cms-home {
		.tabcontent {
			.item {
				margin:1%;
				width:48%;
			}
		}
	}
	/*home testimonials */
	#home-testimonials {
		padding:20px;
		.testimonial-content {
			padding:20px;
		}
	}
	/*product catalog*/

 	#polarcore .block-layered-nav .block-title {
	 	display:none;
 	}
 	#polarcore .block-layered-nav .block-subtitle {
 		margin-top:-52px;
 		margin-bottom:20px;
 	}
 	.category-products {
 		.products-grid {
 			li {
 				margin:1%;
				width:48%;
 			}
 		}
 	}
 	#polarcore.catalogsearch-result-index .block-layered-nav .block-subtitle {
 		margin-top:0px;
 	}
}