/* HEADER NAV - _header-nav.scss */

@media #{$medium-up}{
    .dropdown-menu__4cols{
      @media(max-width:1440px){
        max-height:530px;
        overflow: auto;
      }
      @media(min-width:1441px){
        max-height:850px;
        overflow: auto;
      }
        .dropdown-menu-content{
            @include clearfix;
            max-width:1200px;
            margin:0 auto;
            padding:30px 0;
            overflow: hidden;
        }
        .col-content{
            float:left;
            width:25%;
            box-sizing:border-box;
            position:relative;
            &:after{
                content:'';
                position:absolute;
                top:-50px;
                right:0;
                width:1px;
                height:5000px;
                background:$c-line;
            }
            &:last-child{
                &:after{
                    display:none;
                }
            }
        }
        a{
            color:$white;
            text-transform: capitalize;
            font-size:18px;
            line-height:1em;
            padding:0 50px;
            cursor: pointer;
            &:hover{
                background: none;
            }
        }
        a.lv1{
            color:$green;
            font-family: $headingfont;
        }
        ul{
            @include clearfix;
        }
        .sub-lv1{
            background:none !important;
            padding:10px 0;
            li{
                border-bottom:0 !important;
                background: transparent;
                float:none;
                &:hover{
                    a{
                        background: transparent;
                    }
                }
                &.sub-lv1{
                    padding:10px 0 !important;
                }
            }
        }
        .sub-lv2{
            padding:0;
            li{
                padding:2px 0 !important;
            }
        }
    }
    .header-content{
        position:relative;
        .dropdown-menu{
            position:absolute;
            top:100%;
            left:0;
            width:100%;
            background:$darkblack;
            display:none;
            ul{
                display:block !important;
                position:static !important;
            }
        }
        .nav-primary{
            .level0.parent{
                &:hover,&.menu-active{
                    .dropdown-menu{
                        display:block;
                    }
                }
            }
        }
    }

}
.header-nav {
    display: none;
    text-transform: uppercase;
    background: $nav-background-link-color;
    @media #{$medium-up} {
        display: block;
    }
    #nav .nav-primary {
        @include clearfix();
        display: flex;
        position: static;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        .active {
            background: darken($nav-background-link-color, 15%);
        }
        .level1 .has-children {
            &:before {
                float: right;
                content: $nav-child-arrow;
            }
        }
        li {
            position: static;
            margin: 0;
            padding: 0;
            list-style: none;
            white-space: nowrap;
            a {
                display: inline-block;
                width: 100%;
                font-weight: 100;
                font-size: 18px;
                line-height: 1.5em;
                text-align: left;
                text-decoration: none;
                @media (min-width:1025px) and (max-width:1440px){
                    font-size:16px;
                }
            }
            @media #{$medium-only} {
                a {
                    padding: 0.75rem;
                    font-size: 1rem;
                }
            }
            &.menu-active,
            &:hover {
                @include material-animation-default();
                background: darken($nav-background-link-color, 25%);
            }
            &:focus {
                background: darken($nav-background-link-color, 25%);
            }
        }
        & > li ul {
            /*display: none;
            position: absolute;
            top: 100%;
            margin: 0;
            padding: 0;
            border-top: solid 2px darken($nav-background-link-color, 5%);
            border-bottom: solid 2px darken($nav-background-link-color, 25%);
            background: $nav-background-link-color;
            li:hover {
                background: darken($nav-background-link-color, 25%);
            }*/
        }
        li > ul {
            display: none;
            z-index: 3000;
            ul {
                position: absolute;
                top: 0;
                left: 100%;
                flex: 0 1 auto;
            }
        }
        li.menu-active > ul,
        li:hover > ul {
            display: block;
        }
    }
}

.nav-home-icon {
    vertical-align: middle;
}
