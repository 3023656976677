/* PRODUCT - _product.scss */

.product-view {
  #product-attribute-specs-table {
    .label {
      text-align: left;
    }
  }
  .select.input-box {
    display: flex;
    position: relative;
    max-width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
    background: white;
    @media #{$medium-up} {
      max-width: 50%;
    }
    &:after {
      @include material-icons();
      position: absolute;
      right: 0rem;
      height: 2.5rem;
      margin: auto;
      padding: 0rem 0.5rem;
      color: #444444;
      font-size: 1.2rem;
      line-height: 2.5rem;
      vertical-align: middle;
      pointer-events: none;
      content: "\E313";
      background: rgba(0, 0, 0, 0.05);
      user-select: none;
    }
    .validation-advice {
      top: -1.5rem;
      right: 0;
    }
    select {
      position: relative;
      width: 100%;
      height: 2.5rem;
      padding-left: 0.75rem;
      border: 0;
      outline: none;
      font-size: 0.9rem;
      background: transparent;
      appearance: none;

      @media #{$medium-up} {
        padding: 0.75rem;
      }
      &:hover {
        cursor: pointer;
        background: darken(#ffffff, 1%);
      }
      &:disabled {
        color: #444444;
        background: darken(#e0e0e0, 10%);
      }
      &::-ms-expand {
        display: none;
      }
    }
  }
  .add-to-cart-wrapper .swatch-attr .input-box {
    border: 0;
    background: transparent;
    &:after {
      display: none;
      content: "";
    }
    select {
      &:hover {
        cursor: pointer;
        background: transparent;
      }
      &:disabled {
        background: transparent;
      }
    }
  }
  .product-shop .inner.grouped {
    padding: 0;
    background: transparent;
    .grouped-item {
      display: flex;
      position: relative;
      margin-bottom: 1rem;
      border-bottom: 1px solid #f0f0f0;
      div[id*="options_item"] {
        position: relative;
        .validation-advice {
          width: 50%;
          text-align: right;
        }
      }
      .product-image {
        width: 25%;
      }
      .product-info {
        width: 75%;
        padding: 0.5rem;
        .product-name {
          width: 75%;
          margin: 0;
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
          line-height: 1.5rem;
          @media #{$medium-up} {
            margin-right: 25%;
            font-weight: 700;
            line-height: 1.25rem;
          }
          a {
            color: inherit;
            font-weight: inherit;
          }
        }
      }
      .price-box {
        font-size: 1.2rem;
        text-align: left;
        @media #{$medium-up} {
          position: absolute;
          top: 0.2rem;
          right: 0;
          text-align: right;
        }
      }
    }
  }
  .product-shop .inner.bundle {
    .mdl-checkbox {
      height: auto;
    }
    .price-info {
      .price-box {
        padding: 0;
        .minimal-price,
        .price-from,
        .price-to {
          margin-bottom: 0;
        }
        .price-label {
          display: initial;
        }
      }
    }
    .wrapper {
      .extra-info {
        @include clearfix();
        .availability {
          margin-bottom: 0;
        }
      }
      .add-to-cart-wrapper {
        .price-box-bundle,
        .availability {
          display: none;
        }
        .product-options {
          .mdl-checkbox__label,
          .mdl-radio__label {
            font-size: 1rem;
          }
          dl {
            margin-top: 0;
            margin-bottom: 2rem;
          }
          select {
            padding-right: calc(2.275rem + 0.75rem);

            &[multiple="multiple"] {
              padding-right: 6px;
            }
          }
          .qty-holder {
            width: 100%;
            max-width: 75px;

            .mdl-textfield__error {
              white-space: nowrap;
            }
          }
          .input-box {
            margin-bottom: 0.5rem;

            &--single {
              margin-bottom: 0.5rem;
            }
          }
          .input-radio {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
  .mdl-grid {
    @media #{$small-only} {
      padding: 0;
    }
  }
  .mini-products-list {
    display: flex;
    .product {
      padding: 1rem;
    }
  }
  .product-name {
    font-weight: lighter;
    line-height: 1.5em;
  }
  .short-description {
    margin-bottom: 2em;
  }
  .product-image-gallery {
    .gallery-image {
      display: none;
    }
    .visible {
      display: block;
    }
  }
  .product-image-thumbs {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .mini-description {
    position: relative;
    top: -1rem;
    min-height: 1rem;
    .sku-wrapper {
      float: right;
      margin-top: 0.4rem;
    }
    .sku {
      color: $article-sku-color;
    }
    .article-sku-label {
      padding-right: 0.25rem;
      padding-left: 1rem;
      color: $article-sku-color;
    }
    .ratings {
      display: inline;
      font-size: 1rem;
      .rating-box {
        display: inline-block;
        i {
          position: relative;
          top: 0.3em;
          color: $review-color;
          font-size: 1.5rem;
        }
      }
    }
  }
  .price-info {
    float: left;
    width: 100%;
    font-size: 1.6rem;
    text-align: right;
    @media #{$medium-up} {
      float: right;
      width: auto;
    }
    .price-label {
      display: none;
    }
    .price {
      font-weight: 200; // Or 800?
    }
    .price-box {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
    }
    .old-price,
    .special-price,
    .regular-price {
      margin: 0;
      padding: 0.2rem;
      color: $price-color;
    }
    .regular-price {
      font-size: 1.6rem;
    }
    .old-price {
      order: 3;
      color: $old-price-color;
      text-decoration: line-through;
      .price {
        font-size: 1.3rem;
      }
    }
    .special-price {
      order: 1;
      color: $special-price-color;
      font-weight: 800;
      font-size: 2rem;
    }
    .tier-prices {
      padding-bottom: 1rem;
    }
    .map-info {
      a {
        font-size: 0.9rem;
      }
      .msrp-price-hide-message {
        @include clearfix();
        float: right;
        clear: both;
        font-size: 0.9rem;
      }
      .old-price {
        float: right;
        clear: both;
      }
    }
  }
  select {
    display: block;
    width: 100%;
    padding: 8px 6px;
    border: 1px solid $mdl-grey-300;
    border-radius: 2px;
    color: $mdl-grey-900;
    background-color: #ffffff;
    @media #{$small-only} {
      font-size: 16px;
    }
    @media #{$medium-up} {
      width: auto;
    }
  }
  img {
    transition: ease 0.2s;
  }
  #color_label,
  #size_label {
    font-weight: lighter;
  }
  .required {
    color: $mdl-grey-800;
    em {
      display: none;
      padding-right: 0.25em;
    }
  }
  p.required {
    display: none;
  }

  dd.swatch-attr {
    margin: 0;
    padding: 0;
  }
  .configurable-swatch-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      width: 2em;
      height: 2em;
      margin-right: 0.3em;
      margin-bottom: 1em;
      padding: 0;
      &.selected .swatch-label {
        img {
          border-radius: 50%;
        }
      }
    }
    .x {
      display: none;
    }
    .not-available {
      position: relative;
      &::after {
        position: absolute;
        top: -0.1em;
        left: 0.1em;
        padding: 0.1em;
        color: $mdl-grey-600;
        font-weight: bold;
        font-size: 2em;
        content: "/";
      }
      img {
        opacity: 0.6;
      }
      a,
      a:hover {
        color: grey;
      }
    }
  }
  #configurable_swatch_size {
    .selected .swatch-label {
      border-bottom: 2px solid $select-hover-color;
    }
  }
  .availability {
    .label {
      display: none;
    }
    &.in-stock .value {
      color: $mdl-green-500;
      font-size: 1.2em;
      &:before {
        padding-right: 0.2em;
        content: "\2713";
      }
    }
    &.out-of-stock .value {
      color: $mdl-red-500;
      font-weight: lighter;
      font-size: 1.2em;
      &:after {
        padding-left: 0.2em;
        content: "\2717";
      }
    }
    &.preorder .value {
      color: $mdl-orange-500;
      font-size: 1.2em;
      &:before {
        padding-right: 0.2em;
        content: "\2713";
      }
    }
  }
  .product-name .h1 {
    margin: 0 0 0.25em 0;
    font-weight: 400;
    font-size: 2.2em;
  }
  .product-shop {
    & > .inner {
      position: relative;
      padding: 1rem;
      background: #f1f1f1;
      @media #{$medium-up} {
        padding: 2rem 3rem;
      }
    }
  }

  .sharing-links {
    display: flex;
    flex-direction: column;
    margin: 1em 0;
    padding: 0;
    list-style: none;
  }
  .product-essential {
    width: 100%;
  }
  .product-collateral {
    clear: both;
    width: 100%;
    .mdl-tabs__panel ul,
    .mdl-tabs__panel ol {
      margin-left: 18px;
    }
    h2 {
      display: none;
    }
    h3 {
      text-align: center;
    }
    h4 {
      margin-bottom: 6px;
    }
  }
  .link-compare .material-icons,
  .link-wishlist .material-icons {
    position: relative;
    top: 0.25em;
  }
  .add-to-links {
    display: none; /* Hidden by default */
    /* @include add-to-links(); */
  }
  .product-options {
    dd {
      margin-bottom: 1rem;
    }
  }
  .add-to-cart {
    button.btn-cart {
      @include btn-cart($add-to-cart-bg, $add-to-cart-text-color);
      display: block;
      clear: both;
      max-width: 100%;
      text-transform: uppercase;
      span.inner {
        position: relative;
        top: -0.2em;
      }
      &.out-of-stock {
        color: red;
        background: $mdl-grey-300;
      }
    }
    .qty,
    label[for=qty] {
      display: none;
      width: 2.8em;
    }
  }
}

.mdl-tabs__tab-bar {
  @media #{$small-only} {
    border: 0;
  }
}

.mdl-tabs {
  .tab-polarcore {
    @extend .mdl-tabs__tab;
  }
  @media #{$small-only} {
    display: flex;
    flex-direction: column;
    .mdl-tabs__tab {
      display: flex;
      width: 100%;
      border: 1px #f0f0f0 solid;
      border-bottom: 0;
      &:last-of-type {
        border: 1px #f0f0f0 solid;
      }
      span {
        display: inline-flex;
        width: 100%;
      }
      span:after {
        @include material-icons();
        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        content: "\E313";
        transition: transform 0.2s ease-in;
        transform: rotate(-90deg);
      }
      &.is-active {
        span:after {
          transform: rotate(0deg);
        }
      }
    }
    @keyframes tabsAnimation {
      from {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transform: translate(-50px);
      }
      to {
        opacity: 1;
        max-height: 5000px;
        transform: translate(0px);
      }
    }
    .mdl-tabs__panel.is-active {
      animation: tabsAnimation 0.5s;
    }
  }
  .mdl-tabs__panel {
    margin-top: 1rem;
    @media #{$small-only} {
      padding: 1rem;
    }
  }
  .mdl-tabs__tabs:focus {
    outline: none;
  }
  .box-reviews {
    .review-list {
      order: 1;
      h4 {
        margin-bottom: 0.5rem;
      }
      .reviews {
        .review {
          span {
            display: inline-block;
            min-width: 4rem;
          }
          .rating-box {
            display: inline-block;
            position: relative;
            top: 0.5rem;
          }
        }
      }
    }
    .review-form {
      order: 5;
    }
  }
  .mdl-tabs__tab {
    padding: 0 1rem;
    @media #{$medium-up} {
      padding: 0 24px;
    }
  }
}

.box-up-sell {
  position: relative;
  padding-bottom: 2rem;
  &:before {
    position: absolute;
    top: 1.7rem;
    width: 100%;
    border-bottom: $mdl-grey-300 1px solid;
    content: "";
  }
  h2 {
    position: relative;
    text-align: center;
    @media #{$small-only} {
      line-height: 2rem;
    }
    .center {
      padding: 0 2rem;
      background: white;
      @media #{$small-only} {
        display: inline-block;
        margin-top: 0.5rem;
        padding: 0 2rem;
      }
    }
  }
  ul {
    li.up-sell-item {
      @include grid-price-box();
      padding: 1rem;
      .product-name {
        margin-bottom: 0.5rem;
        font-size: 1rem;
        text-align: center;
      }
    }
  }
}

.block-related {
  padding-bottom: 2rem;
  .block-title {
    // mixins.scss
    @include h2-with-line();
  }
  ol {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    li.item {
      @include grid-price-box();
      position: relative;
      flex-basis: 100%;
      width: 100%;
      padding: 1rem;
      text-align: left;
      @media #{$medium-up} {
        flex-basis: 50%;
        width: 50%;
      }
      @media #{$large-up} {
        flex-basis: 33%;
        width: 33%;
      }
      &:hover {
        box-shadow: none;
      }
      .product-info .product-name {
        text-align: left;
      }
      .link-wishlist {
        display: none;
      }
      .product {
        display: flex;
        border: 2px solid #fafafa;
        @media #{$small-only} {
          .product-name {
            font-size: 1rem;
          }
        }
        .product-image {
          min-width: 128px;
          img {
            max-width: 128px;
            max-height: 128px;
          }
        }
      }
      .price-box {
        justify-content: initial;
        align-items: initial;
      }
    }
    .related-checkbox,
    .mdl-checkbox.is-upgraded {
      position: absolute;
      top: 1.5rem;
      left: 2rem;
    }
  }
}
.products-grid .slick-track {
  .item:hover {
    box-shadow: none;
  }
  .up-sell-item.item {
    width: 200px;
  }
  .up-sell-item.item img {
    margin: 0 auto;
  }
}
